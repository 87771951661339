import { createSlice } from '@reduxjs/toolkit'

export interface CountdownTimerProps {
    open: boolean
}

const initialState: CountdownTimerProps = {
    open: false
}

export const countdownTimerSlice = createSlice({
    name: 'countdownTimer',
    initialState,
    reducers: {
        toggleCurtains: (state) => {
            state.open = !state.open
        },
        openCurtains: (state) => {
            state.open = true
        },
        closeCurtains: (state) => {
            state.open = false
        },
    },
})

export const { toggleCurtains, openCurtains, closeCurtains } = countdownTimerSlice.actions
export default countdownTimerSlice.reducer
