import { useEffect } from 'react';
import { Note, WebMidi } from 'webmidi';
import { useAppDispatch } from '../hooks';
import { MidiConfig } from './TriggerConfig';

const DEVICE_NAME = "Launchpad Mini"

export const MidiTrigger = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        // Enable WebMidi and set up listeners
        WebMidi.enable({ sysex: true })
            .then(() => {
                const input = WebMidi.getInputByName(DEVICE_NAME);
                const output = WebMidi.getOutputByName(DEVICE_NAME);
                const outputChannel = output?.channels[1];

                console.log(`Selected devices: input ${input?.name}, output ${output?.name}`)

                if (input && outputChannel) {
                    setupMidi(outputChannel);

                    const noteOnListener = (e: any) => {
                        activatePad(outputChannel, e.note, dispatch);
                    };

                    input.addListener('noteon', noteOnListener);

                    // Cleanup function to unmount event listeners and reset MIDI state
                    return () => {
                        input.removeListener('noteon', noteOnListener);
                        resetMidi(outputChannel); // Custom function to reset the MIDI pads or settings
                    };
                }
            })
            .catch((err) => console.log("WebMidi could not be enabled", err));

        // Optional: Cleanup when WebMidi is disabled or if WebMidi.enable fails
        return () => {
            if (WebMidi.inputs.length || WebMidi.outputs.length) {
                WebMidi.disable();
            }
        };
    }, [dispatch]);

    return <div className="midi-trigger"></div>;
};

const activatePad = async (outputChannel: any, note: Note, dispatch: any) => {
    if (MidiConfig.triggers.find(t => t.note === note.number)) {
        outputChannel.send([146, note.number, 29]);
        dispatchFunction(outputChannel, note, dispatch);
    }
};

const dispatchFunction = (outputChannel: any, note: Note, dispatch: any) => {
    MidiConfig.triggers.forEach((trigger: any) => {
        if (trigger.note === note.number) {
            dispatch(trigger.function);

            // Turn off pad after dispatch
            setTimeout(() => {
                initPad(outputChannel, trigger);
            }, 1200);
        }
    });
};

const setupMidi = (outputChannel: any) => {
    // Enable Programmer Mode
    outputChannel.send([240, 0, 32, 41, 2, 13, 14, 1, 247]);

    // Reset remainders
    Array(100).fill(0).forEach((_, i) => outputChannel.send([144, i, 0]));

    // Display "TimeWaster" (optional)
    // outputChannel.send([240, 0, 32, 41, 2, 13, 7, 0, 16, 0, 29, 84, 105, 109, 101, 87, 97, 115, 116, 101, 114, 247]);

    outputChannel.send([240, 0, 32, 41, 2, 13, 10, 0, 0, 247]);

    // Activate armed triggers
    MidiConfig.triggers.forEach((trigger: any) => {
        initPad(outputChannel, trigger);
    });
};

const initPad = (outputChannel: any, trigger: any) => {
    if (trigger && trigger.note) {
        if (trigger.color) {
            outputChannel.send([144, trigger.note, trigger.color]);
        } else {
            outputChannel.send([144, trigger.note, 41]);
        }
    }
};

// Optional function to reset the MIDI configuration on unmount
const resetMidi = (outputChannel: any) => {
    if (outputChannel) {
        // Switch back to live mode
        outputChannel.send([240, 0, 32, 41, 2, 13, 14, 0, 247]);
    }
};
