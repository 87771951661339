import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface MediaPlayerProps {
    ref: string,
    duration: number,
}

const initialState: MediaPlayerProps = {
    ref: "",
    duration: 0,
}

export const mediaPlayerSlice = createSlice({
    name: 'media-player',
    initialState,
    reducers: {
        play: (state, action: PayloadAction<string>) => {
            state.ref = action.payload
            state.duration = 0
        },
        playFor: (state, action: PayloadAction<MediaPlayerProps>) => {
            state.ref = action.payload.ref
            state.duration = action.payload.duration
        },
        unload: () => {
            return initialState
        }
    },
})

export const { play, playFor, unload } = mediaPlayerSlice.actions
export default mediaPlayerSlice.reducer