import { configureStore } from "@reduxjs/toolkit";
import mediaPlayerReducer from './fragment/media-player/MediaPlayerSlice'
import neonTitleReducer from './fragment/neon-title/NeonTitleSlice'
import counterReducer from './fragment/counter/CounterSlice'
import countdownTimerReducer from "./fragment/countdown-timer/CountdownTimerSlice";

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        countdownTimer: countdownTimerReducer,
        mediaPlayer: mediaPlayerReducer,
        neonTitle: neonTitleReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch