import React, { useState, useEffect, createContext, ReactNode } from 'react';

// Define the shape of the media file entry from the JSON
interface MediaFile {
  ref: string;
  path: string;
}

// Define the shape of the preloaded media object
interface PreloadedMedia {
  [ref: string]: string;
}

// Create a context for media preloading
const PreloadMediaContext = createContext<((ref: string) => string | undefined) | undefined>(undefined);

// PreloadMediaProvider component
const PreloadMediaProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const [preloadedMedia, setPreloadedMedia] = useState<PreloadedMedia>({});

  // Fetch the media_resources.json and preload all media files as blobs
  useEffect(() => {
    const fetchMediaResources = async () => {
      try {
        // Fetch the media_resources.json
        const response = await fetch('./res/media_resources.json');
        const mediaFiles: MediaFile[] = await response.json();
        
        const preloadedMedia: PreloadedMedia = {};

        // Fetch blobs for each media file
        await Promise.all(
          mediaFiles.map(async (mediaFile) => {
            try {
              const fileResponse = await fetch(mediaFile.path);
              const blob = await fileResponse.blob();
              // Map ref to blob URL
              preloadedMedia[mediaFile.ref] = URL.createObjectURL(blob);
            } catch (error) {
              console.error(`Failed to preload ${mediaFile.path}`, error);
              // Add original path instead
              preloadedMedia[mediaFile.ref] = mediaFile.path;
            }
          })
        );

        // Store the preloaded media blobs in state
        setPreloadedMedia(preloadedMedia);
        console.log("Media preloaded")
      } catch (error) {
        console.error('Failed to fetch media resources', error);
      }
    };

    fetchMediaResources();
  }, []);

  // Function to get the blob URL by ref
  const getBlobByRef = (ref: string): string | undefined => {
    return preloadedMedia[ref];
  };

  return (
    <PreloadMediaContext.Provider value={getBlobByRef}>
      {children}
    </PreloadMediaContext.Provider>
  );
};

// Export the context and provider
export { PreloadMediaProvider, PreloadMediaContext };
