import { useAppDispatch, useAppSelector } from '../../hooks';
import { PreloadMediaContext, PreloadMediaProvider } from '../PreloadMediaProvider/PreloadMediaProvider';
import ReactPlayer from 'react-player';
import { MediaPlayerProps, unload } from './MediaPlayerSlice';
import './MediaPlayer.css';
import { useContext, useRef } from 'react';

export default function MediaPlayer() {
    const dispatch = useAppDispatch()
    const props: MediaPlayerProps = useAppSelector((state: any) => state.mediaPlayer)

    const ref = useRef<ReactPlayer>(null);

    const getBlobByRef = useContext(PreloadMediaContext);
    if (!getBlobByRef) return <div>Cannot load media provider</div>
    const blobUrl = getBlobByRef(props.ref)

    let endTime: number;

    const calculateEnd = () => {
        if(props.duration != 0){
            endTime = (ref.current?.getCurrentTime() ?? 0) + props.duration
        }
    }

    const calculateRemaining = ({playedSeconds, _loadedSeconds}: any) => {
        if(props.duration != 0){
            if(playedSeconds > endTime){
                dispatch(unload())
                console.log(`ended: ${playedSeconds}, ${endTime}`);
            }
        }
    }

    return (
        <div className="mediaPlayer">
            <ReactPlayer
                style={{ display: props.ref ? "block" : "none" }}
                ref={ref}
                url={blobUrl}
                playing={!!props.ref}
                controls={false}
                height={"100%"}
                width={"100%"}
                onStart={calculateEnd}
                onProgress={calculateRemaining}
                onEnded={() => dispatch(unload())} />
        </div>
    );
}
