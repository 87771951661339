import { useState, useEffect } from 'react';
import './CountdownTimer.css';
import Wave from 'react-wavify';
import { CountdownTimerProps, openCurtains } from './CountdownTimerSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';

const CountdownTimer = () => {
  const props: CountdownTimerProps = useAppSelector((state: any) => state.countdownTimer)
  const dispatch = useAppDispatch()
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const currentTime = new Date();
    
    // Calculate the next 15-minute mark
    const nextQuarterHour = new Date(currentTime);
    nextQuarterHour.setMilliseconds(0);
    nextQuarterHour.setSeconds(0);
    const currentMinutes = currentTime.getMinutes();
    const minutesToAdd = 15 - (currentMinutes % 15);
    nextQuarterHour.setMinutes(currentMinutes + minutesToAdd);

    const difference = nextQuarterHour.getTime() - currentTime.getTime();

    if (difference > 1000) {
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return { minutes, seconds };
    } else {
      // If the target time has passed, return zeros
      dispatch(openCurtains())
      return { minutes: 0, seconds: 0 };
    }
  }

  function addPrefixZero(value: number) {
    return (value.toString().length === 1 ? "0" : "") + value;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(() => calculateTimeRemaining());
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  if (!props.open && timeRemaining.minutes + timeRemaining.seconds > 0) {
    return (
      <div className="countdown-timer">
        <div className="curtains">
          <div>
            <h3>Starting soon</h3>
            <p>
              {timeRemaining.minutes}:{addPrefixZero(timeRemaining.seconds)}
            </p>
          </div>

          <div className="waves">
            <Wave fill='#ffffff77'
              options={{
                height: 64,
                amplitude: 75,
                speed: 0.15,
                points: 3
              }} />
            <Wave fill='#ffffff55'
              options={{
                height: 50,
                amplitude: 75,
                speed: 0.20,
                points: 5
              }} />
            <Wave fill='#ffffff88'
              options={{
                height: 75,
                amplitude: 75,
                speed: 0.10,
                points: 7
              }} />
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="countdown-timer"></div>;
  }
};

export default CountdownTimer;
